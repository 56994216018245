//@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');


.page-container{
   // height: 93vh;
}
/* Navbar styles */
.navbar-cert {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: 80px;
  padding: 0 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

  .logoAll img{
    margin-left: 50px;
    width: 200px!important;
    height: 60px;
    
  }
  
  .nav-links {
    display: flex;
  }
  
  .nav-link {
    font-size: 16px;
    color: #000;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
  
    &:hover {
      color: #000;
    }
  }
  .nav-item{
  margin-right: 50px;
  margin-top: 10px;
  right: 0;
  font-weight: 600;
  color: #000;
  }
  .nav-item a{
    text-decoration: none;
    font-size: 1.2rem;
    right: 0;
  font-weight: 600;
  color: #000;
  }
  
  .nav-item a:hover{
  color: #000;
  }
}


/* Footer styles */
.footerrall {
    background-color: #e0e0e0;
    color: #333;
    text-align: center!important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    bottom: 0!important;
  }
  
  .footerr-text {
    font-size: 14px;
    text-align: center!important;
  }


  .userNameSec{
    display: flex;
    flex-direction: row;
    height: auto;
    padding: 20px;
align-items: center;
background: linear-gradient(90deg, #40bdee 0%, #467fbd 100%);
box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3);

.userNameSec1{
    display: flex;
    flex-direction: row;
}
.userImg1{
    height: 80px;
    width: 85px;
    border-radius: 50%;
    background-color: #e9e9e9;
    padding: 10px;
}

.userNameall{
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    margin: 10px 15px!important;
    line-height: 2rem!important;
}

.credText{
    font-size: 1rem;
    font-weight: 600;
    color: #ffff;
    margin: 15px;
}
  }

.cardSecAllBMM{
    height: auto;
    min-height: 80vh!important;
    width: 100%;
   // background-color: #f6f6f6;
    padding: 0px!important;
    .cardSecAll1{
        height: auto;
//background-color: #fff;
//box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
padding: 0px!important;
margin: 50px!important;
@media only screen and (max-width: 768px) {
  margin: auto!important;
}
    }
}

  .blog-cardminiBMM {
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%!important;
    margin: 30px 0px 30px 0px!important;
    height: auto!important;
    background-color: #fff;
    
    .blogImg1BMM{
        padding: 45px 20px;
        background: #f5f5f5;
    }
    .blog-card__image {
      width: 100%;
      height: auto;
     
      border: 4px solid #013a63;
    }
    
    .blog-card__content {
      padding: 16px;
      
      .blog-card__title {
        font-size: 1.4rem!important;
        margin: 0 0 8px 0;
        height: 80px;
        color: #467fbd;
        font-weight: 600;
      }

      .blog-card__issued-by {
        font-size: 1rem;
        color: #000000;
        margin: 0;
      }
      
      .blog-card__date {
        font-size: 1.1rem;
        color: #666;
        margin: 0;
        margin-bottom: 4px;
      }
    }
  }


  @media only screen and (max-width: 768px) {
    .navbar-cert {
      height: 90px;
      padding: 0 5px;
    }
    .logoAll img {
     // margin-left: 5px;
      width: 100px!important;
      height: 60px!important;
    }

}


.certificatedminiBMM {
  width: 100%;
  height: auto!important; // Adjust the height as necessary to fit the card
  background-color: white;
  border: 3px solid #1c3a61;
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-image: url('../../img/backcert1.jpg');
  background-size: cover;
  //background-image: url('../../img/blue-abstract-gradient-wave-wallpaper.jpg');
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

  .bodboxBMM {
      .headerBMM {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          
          .favico {
              img {
                  width: 30px!important; // Small favicon size
                  height: auto;
              }
          }

          .title {
              font-size: 16px;
              font-weight: bold;
              color: #1c3a61;
          }
      }

      .title1 {
          font-size: 12px;
          font-weight: normal;
          margin-top: 5px;
          color: #000!important;
          @media only screen and (max-width: 768px) {
            padding-left: 20px!important;
          }
      }

      .body {
          margin-top: 10px;
          font-size: 12px;
          line-height: 1.4;
          
          .name {
              font-size: 14px;
              font-weight: bold;
              margin-bottom: 5px;
              color: #467fbd!important;
              @media only screen and (max-width: 768px) {
                padding-left: 20px!important;
              }
          }
      }

      .qrcode-container {
          margin-top: 10px;
          
          .qrCode {
              width: 60px; // Adjust the size of the QR code
              height: 60px;
          }
      }

      .signature {
          margin-top: 5px;
          font-size: 14px;
          font-weight: bold;
          color: #1c3a61;
          @media only screen and (max-width: 768px) {
            padding-left: 20px!important;
          }
      }

      .signature1 {
          font-size: 10px;
          margin-top: 5px;
      }
  }
}