.footersection{
    background: #fff;
}
p{
    color: #000;
}
.social-div a{
     background: #1b2a41!important;
}
.social-div Fontawesome{
    background: #1b2a41!important;
}
.footer2 .nav-link{
    color: #000!important;
}